import Aos from "aos";
import React, { lazy, Suspense, useEffect } from "react";
// import './App.css';
import "./App.less";
// import AppRouter from './Routes';
import LoadingScreen from "./components/loadingScreen";
import ScrollToTop from "./utils/ScrollToTop";
import { useLocation } from "react-router-dom";

const AppRouter = lazy(() => import("./Routes"));

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
   
    window.requestAnimationFrame(() => {
      
      window.scrollTo({
        behavior:'smooth',
        top:0
      });
    })
  }, [pathname]);
  useEffect(() => {
    const navigationEntry = performance.getEntriesByType(
      "navigation"
    );

    if (navigationEntry && navigationEntry.length > 0) {
      // Use the `type` from the newer `Navigation Timing Level 2` specconst navigationType = navigationEntry.type;
      const navigationType = (navigationEntry[0] as any).type;
      // console.log(navigationType);
      if (navigationType === "reload") {
        window.requestAnimationFrame(() => {
          window.scrollTo({
            behavior:'smooth',
            top:0
          });
        })
      // } else if (navigationType === "navigate") {
      //   console.log(
      //     "Page loaded via normal navigation (e.g., clicking a link)"
      //   );
      // } else if (navigationType === "back_forward") {
      //   console.log("Page was accessed via back/forward navigation.");
      } 
      else {
        window.requestAnimationFrame(() => {
          window.scrollTo({
            behavior:'smooth',
            top:0
          });
        })
        // console.log("Some other type of navigation occurred.");
      }
    } else {
      // console.log("Navigation type could not be detected.");
    }
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <Suspense fallback={<div></div>}>
        <AppRouter />
      </Suspense>
    </>
  );
};

export default App;
